import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useFeatureFlags } from '@/hooks/use-feature-flags'

const useIwMaintenance = () => {
  const { data: featureFlag } = useFeatureFlags()
  const router = useRouter()

  useEffect(() => {
    if (!featureFlag || !featureFlag.instantwire_maintenance_mode) return
    router.push('/maintenance')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlag])
}

export default useIwMaintenance
