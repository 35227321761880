import { useContext, useMemo } from 'react'

import Image from 'next/image'

import SwapContext from '@/contexts/swap'
import useTrans from '@/hooks/useTrans'
import { staticImportIcon } from '@/root/src/utils/static-import-icon'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

type TokenPickerProps = {
  'data-testid'?: string
  nickName: string
  disableDropdown?: boolean
  boxProps?: BoxProps
  allowCustomMintAddress?: boolean
  blackList?: string[]
  hideSelected?: boolean
  disableFallbackToSwapToken?: boolean
}

const TokenPicker = (props: TokenPickerProps) => {
  const { nickName, disableFallbackToSwapToken } = props

  const { tokenIn, tokenOut } = useContext(SwapContext)
  const trans = useTrans()

  const token = useMemo(
    () => (disableFallbackToSwapToken ? undefined : nickName === 'A' ? tokenIn : tokenOut),
    [nickName, tokenIn, tokenOut, disableFallbackToSwapToken],
  )

  return (
    <>
      <Box
        data-testid={props['data-testid']}
        bgcolor="#f6fbff"
        className="rounded-[10px] px-4 py-3"
        {...props.boxProps}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="shrink-0 grow-0 h-full"
        >
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, md: 1 }}>
            {token && (
              <Box className="w-[20px] md:w-[28px]">
                <Image
                  src={staticImportIcon(`fiat-${token.name.toLowerCase()}`)}
                  alt={token.name}
                  className="max-w-full rounded-full w-[20px] md:w-[28px] h-[20px] md:h-[28px] object-cover"
                />
              </Box>
            )}
            <Typography
              data-testid={`token-${nickName.toLowerCase()}-display-name`}
              className={`text-base ${
                token ? '' : 'italic opacity-60'
              } select-none text-[#29292E] font-semibold not-italic`}
            >
              {token ? token.name : trans.common.token_picker_please_select}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default TokenPicker
