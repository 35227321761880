import { Stack, Typography, Tooltip } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'

const Information = () => {
  const trans = useTrans()

  return (
    <Stack className="my-4 w-full" gap={1}>
      <Stack direction="row" spacing={1}>
        <Image src={staticImportIcon(IconName.CLOCK)} alt={IconName.CLOCK} />
        <Typography className="text-iw-neutral-black flex-1">
          {trans.landing.hero.arrive}&nbsp;
          <Tooltip
            title={
              <Typography className="text-center text-white text-xs font-normal font-['Inter'] leading-[18px]">
                {trans.landing.hero.arrive_description}
              </Typography>
            }
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#2d2f38',
                  '& .MuiTooltip-arrow': {
                    color: '#2d2f38',
                  },
                },
              },
            }}
          >
            <span
              className="font-semibold text-[#1e5e8d] border-b-[1px] border-dashed pb-[2px]"
              style={{
                borderBottomColor: '#1e5e8d',
              }}
            >
              {trans.landing.hero.arrive_time}
            </span>
          </Tooltip>
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Information
