import { ChangeEvent, MouseEvent, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { customTokens } from '@/constants/token'
import SwapContext, { FIAT_USD_OBJ } from '@/contexts/swap'
import useCalculateDiscountAmount from '@/hooks/use-calculate-discount-amount'
import { useFeatureFlags } from '@/hooks/use-feature-flags'
import { Transaction } from '@/model/transaction'
import { exchangeRates } from '@/root/src/constants/exchange_rates'
import useTrans from '@/root/src/hooks/useTrans'

import View from './view'

type Props = {
  isLanding?: boolean
  handleSubmit: (transaction: Transaction) => void
  enteredTransaction?: Transaction
}

const TransferInputPage = (props: Props) => {
  const { isLanding, handleSubmit, enteredTransaction } = props

  const {
    tokenIn,
    tokenOut,
    setMintAddresses,
    isLoading,
    inputAmount,
    outputAmount,
    setInputAmount,
    displayExchangeRate,
    fee,
    displayDiscountFee,
    originalFee,
    refreshRate: handleRefreshRateClick,
  } = useContext(SwapContext)

  const { data: featureFlags } = useFeatureFlags()
  const { discountAmount, discountWirePoints, discountTier } = useCalculateDiscountAmount({
    amount: inputAmount,
    currency: FIAT_USD_OBJ.name,
  })
  const [appliedDiscount, setAppliedDiscount] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const trans = useTrans()
  const inputRef = useRef<HTMLInputElement>()
  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element
  }
  const timerRef = useRef<any>(null)

  // update data entered from landing page
  useEffect(() => {
    if (!enteredTransaction) return

    setMintAddresses([enteredTransaction.tokenIn.address, enteredTransaction.tokenOut.address])
    setInputAmount(enteredTransaction.amount.toString())
    setAppliedDiscount((enteredTransaction.discountAmount || 0) > 0)
  }, [enteredTransaction, setMintAddresses, setInputAmount, setAppliedDiscount])

  const transaction = useMemo(
    () =>
      ({
        amount: inputAmount,
        outputAmount,
        displayExchangeRate,
        currencyIn: tokenIn.name,
        currencyOut: tokenOut.name,
        createdAt: new Date(),
        tokenIn,
        tokenOut,
        fee,
        status: 'unsaved',
        discountAmount: appliedDiscount ? discountAmount : 0,
        discountWirePoints: discountWirePoints,
        originalFee,
      } as Transaction),
    [
      inputAmount,
      outputAmount,
      displayExchangeRate,
      tokenIn,
      tokenOut,
      fee,
      discountAmount,
      discountWirePoints,
      appliedDiscount,
      originalFee,
    ],
  )

  const toggleApplyDiscount = () => {
    setAppliedDiscount((prevAppliedDiscount) => !prevAppliedDiscount)
  }

  const handleInputAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputAmount(value)

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setError(validate(value))
    }, 300)
  }

  const validate = (data?: string) => {
    if (!data) {
      return trans.instantwire_transaction.draft_transaction.empty_input_amount
    }

    const parsedValue = parseFloat(data)
    if (parsedValue < featureFlags?.min_transfer_amount) {
      return trans.instantwire_transaction.draft_transaction.lowest_input_amount(
        featureFlags?.min_transfer_amount,
        'USD',
      )
    }

    if (parsedValue < featureFlags?.min_transfer_amount) {
      return trans.instantwire_transaction.draft_transaction.lowest_input_amount(
        featureFlags?.min_transfer_amount,
        'USD',
      )
    }

    if (parsedValue > featureFlags?.max_transfer_amount) {
      return trans.instantwire_transaction.draft_transaction.exceed_input_amount(
        featureFlags?.max_transfer_amount,
        'USD',
      )
    }

    const convertToUsdRate = exchangeRates[`USD${transaction.currencyIn}`]
    const exceedLimit = parsedValue / convertToUsdRate > featureFlags?.max_transfer_amount

    if (exceedLimit) {
      return trans.instantwire_transaction.draft_transaction.exceed_input_amount(
        featureFlags?.max_transfer_amount,
        'USD',
      )
    }

    return ''
  }

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const error = validate(inputAmount)
    if (!error) {
      handleSubmit(transaction)
    } else {
      setError(error)
    }
  }

  const disabledSubmit = !!error || isLoading

  const computedProps = {
    isLanding,
    tokenIn,
    tokenOut,
    inputAmount: transaction.amount.toString(),
    isLoading: isLoading,
    outputAmount,
    isGaslessModeEnabled: false,
    handleInputAmountChange,
    handleRefreshRateClick,
    onSubmit,
    disabledSubmit,
    setTextInputRef,
    customTokens,
    displayExchangeRate,
    error,
    discountAmount,
    discountWirePoints,
    toggleApplyDiscount,
    appliedDiscount: appliedDiscount && discountAmount > 0,
    discountTier,
    fee,
    displayDiscountFee,
    originalFee,
  }

  return <View {...computedProps} />
}

TransferInputPage.displayName = 'TransferInputContainer'
export default TransferInputPage
