import React, { ChangeEvent } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}
const CustomNumericFormat = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...event,
            target: {
              ...event.target,
              name: props.name,
              value: event.target.value.replaceAll(',', ''),
            },
          })
        }}
        isAllowed={(values) => {
          const { value } = values
          return value.replace(/[,\.]/g, '').length <= 15
        }}
        thousandSeparator
        valueIsNumericString
      />
    )
  },
)

export default CustomNumericFormat
