export const exchangeRates: { [key: string]: any } = {
  USDUSD: 1,
  USDVND: 23987,
  USDCNY: 7.3136,
  USDKRW: 1333.5723,
  USDJPY: 146.394,
  USDPHP: 56.69,
  USDEUR: 0.9323,
  USDGBP: 0.8,
  USDNGN: 745.808,
  VNDUSD: 0.000041,
  VNDVND: 1,
  VNDCNY: 0.0003,
  VNDKRW: 0.0555,
  VNDJPY: 0.0061,
  VNDPHP: 0.0024,
  VNDEUR: 0.00004,
  VNDGBP: 0.00003,
  VNDNGN: 32.5657,
  CNYUSD: 0.1367,
  CNYVND: 3285.14,
  CNY: 1,
  CNYKRW: 182.3326,
  CNYJPY: 20.015,
  CNYPHP: 7.747,
  CNYEUR: 0.13,
  CNYGBP: 0.11,
  CNYNGN: 102.546,
  KRWUSD: 0.0007,
  KRWVND: 18,
  KRWCNY: 0.00551,
  KRWKRW: 1,
  KRWJPY: 0.11,
  KRWPHP: 0.0425,
  KRWEUR: 0.0007,
  KRWGBP: 0.0006,
  KRWNGN: 0.5626,
  JPYUSD: 0.0068,
  JPYVND: 164.029,
  JPYCNY: 0.0521,
  JPYKRW: 9.1117,
  JPYJPY: 1,
  JPYPHP: 0.3873,
  JPYEUR: 0.0064,
  JPYGBP: 0.0055,
  JPYNGN: 5.0693,
  PHPUSD: 0.0176,
  PHPVND: 423.404,
  PHPCNY: 0.129,
  PHPKRW: 23.5189,
  PHPJPY: 2.5817,
  PHPPHP: 1,
  PHPEUR: 0.0164,
  PHPGBP: 0.0141,
  PHPNGN: 13.1487,
  EURUSD: 1.07258,
  EURVND: 25709.1,
  EURCNY: 7.84855,
  EURKRW: 1430.0778,
  EURJPY: 156.987,
  EURPHP: 60.8032,
  EUREUR: 1,
  EURGBP: 0.8578,
  EURNGN: 801.288,
  GBPUSD: 1.25,
  GBPVND: 29970.5,
  GBPCNY: 9.14324,
  GBPKRW: 1666.7122,
  GBPJPY: 182.991,
  GBPPHP: 70.8823,
  GBPEUR: 1.1658,
  GBPGBP: 1,
  GBPNGN: 932.053,
  NGNNGN: 1,
  NGNUSD: 0.001341,
  NGNVND: 32.5657,
  NGNCNY: 0.0097,
  NGNKRW: 1.7775,
  NGNJPY: 0.1973,
  NGNPHP: 0.076,
  NGNEUR: 0.0012,
  NGNGBP: 0.001,
}
