import { ChangeEvent, memo, MouseEvent } from 'react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import type { SwapTokenInfo } from '@/hooks/use-tokens'

import BaseAmountInput from '@/components/base/BaseAmountInput'
import TokenPicker from '@/components/base/TokenPicker'
import useTrans from '@/hooks/useTrans'
import Rate from './components/rate'
import Fee from './components/fee'
import Information from './components/information'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'
import useSessionStorage from '@/hooks/useSessionStorage'
import { FIAT_VND_OBJ } from '@/contexts/swap'

type TransferInputPageProps = {
  isLanding?: boolean
  tokenIn: SwapTokenInfo
  tokenOut: SwapTokenInfo
  inputAmount: string
  outputAmount: string
  isLoading: boolean
  isGaslessModeEnabled: boolean
  handleInputAmountChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleRefreshRateClick: (e: MouseEvent<HTMLButtonElement>) => void
  onSubmit: (e: MouseEvent<HTMLButtonElement>) => void
  disabledSubmit: boolean
  setTextInputRef: (element: HTMLInputElement) => void
  displayExchangeRate: string
  error: string | null
  discountAmount: number
  discountWirePoints: number
  toggleApplyDiscount: () => void
  appliedDiscount: boolean
  discountTier: number
  fee: string
  originalFee?: string
  displayDiscountFee?: number
}

const View = (props: TransferInputPageProps) => {
  const {
    isLanding,
    tokenIn,
    tokenOut,
    inputAmount,
    outputAmount,
    isLoading,
    handleInputAmountChange,
    handleRefreshRateClick,
    onSubmit,
    disabledSubmit,
    setTextInputRef,
    displayExchangeRate,
    error,
    discountAmount,
    appliedDiscount,
    fee,
    originalFee,
    displayDiscountFee,
  } = props
  const trans = useTrans()
  const router = useRouter()
  const [, setHasShownOngoingTransactionModal] = useSessionStorage(
    'hasShownOngoingTransactionModal',
    false,
  )

  if (!tokenIn || !tokenOut) return null

  const handleStartTransaction = (e: MouseEvent<HTMLButtonElement>) => {
    setHasShownOngoingTransactionModal(false)
    onSubmit(e)
  }

  return (
    <Container maxWidth="sm" className={'mt-10 sm:mt-20 md:max-w-[580px] p-0'}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        className="p-4 sm:p-6 rounded-xl shadow-xl bg-white"
      >
        <Stack className="w-full" direction="column">
          <Typography className="text-[#2C2E31]" variant="body1" fontWeight="bold">
            {trans.home.you_send}
          </Typography>
          <Box
            data-testid="input-wrapper"
            className="w-full border-2 rounded-2xl px-4 py-3 mt-2 sm:h-auto h-[56px] overflow-hidden"
          >
            <Stack direction="row" className="h-full">
              <TokenPicker data-testid="token-picker-a" nickName="A" hideSelected={true} />
              <Stack
                className="flex basis-full h-full md:h-auto"
                justifyContent="center"
                alignItems="end"
              >
                <BaseAmountInput
                  data-testid="input-amount-input"
                  id="input-amount-input"
                  inputRef={setTextInputRef}
                  value={inputAmount}
                  onChange={handleInputAmountChange}
                />
              </Stack>
            </Stack>
          </Box>
          <Typography className="pt-2" color="red">
            {error}
          </Typography>
        </Stack>

        <Rate
          tokenIn={tokenIn}
          tokenOut={tokenOut}
          exchangeRate={displayExchangeRate}
          isLoadingRate={isLoading}
          handleRefreshRateClick={handleRefreshRateClick}
        />

        <Stack className="w-full mt-5" direction="column">
          <Typography className="text-[#2C2E31]" variant="body1" fontWeight="bold">
            {trans.home.recipient_receive}
          </Typography>
          <Box
            data-testid="output-wrapper"
            className="w-full border-2 rounded-2xl px-4 py-3 mt-2 sm:h-auto h-[56px] overflow-hidden"
          >
            <Stack direction="row" className="h-full">
              <TokenPicker data-testid="token-picker-b" nickName="B" hideSelected={true} />
              <Stack
                className="flex basis-full h-full md:h-auto"
                justifyContent="center"
                alignItems="end"
              >
                <BaseAmountInput
                  id="output-amount-input"
                  data-testid="output-amount-input"
                  decimals={FIAT_VND_OBJ.decimals}
                  value={outputAmount || 0}
                  readOnly
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>

        <Fee
          {...{
            discountAmount,
            appliedDiscount,
            tokenIn,
            inputAmount,
            fee,
            originalFee,
            displayDiscountFee,
          }}
        />

        <Information />

        <Stack className="w-full pt-4" spacing={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleStartTransaction}
            disabled={disabledSubmit}
            className="hover:border-none border-none relative h-[48px] md:h-[56px] font-bold text-lg"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Image
                src={staticImportIcon(IconName.FLASH_INPUT)}
                alt={IconName.FLASH_INPUT}
                width={24}
                height={24}
              />
              {trans.transaction_input.start}
            </Stack>
          </Button>
          {!isLanding && (
            <Button fullWidth variant="text" onClick={() => router.push('/')}>
              {trans.transaction_input.cancel}
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

const TransferInputWithMemo = memo(View)
TransferInputWithMemo.displayName = 'TransferInputView'
export default TransferInputWithMemo
