import { MouseEvent, useCallback } from 'react'
import { IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import type { SwapTokenInfo } from '@/hooks/use-tokens'
import { IconName, SVGIcon } from '@/root/src/utils/static-import-icon'
import { currencyPriorities } from '@/constants/token'
import useTrans from '@/hooks/useTrans'
import { FIAT_VND_OBJ } from '@/contexts/swap'

interface RateProps {
  tokenIn: SwapTokenInfo
  tokenOut: SwapTokenInfo
  exchangeRate: string
  handleRefreshRateClick: (e: MouseEvent<HTMLButtonElement>) => void
  isLoadingRate: boolean
}

function Rate(props: RateProps) {
  const { tokenIn, tokenOut, exchangeRate, isLoadingRate, handleRefreshRateClick } = props
  const trans = useTrans()

  const getTokenRateOrder = useCallback((tokenIn: SwapTokenInfo, tokenOut: SwapTokenInfo) => {
    for (const tokenName of currencyPriorities) {
      if (tokenIn.name === tokenName) {
        return [tokenIn.name, tokenOut.name]
      }
      if (tokenOut.name === tokenName) {
        return [tokenOut.name, tokenIn.name]
      }
    }
  }, [])

  const [firstTokenName, lastTokenName] = getTokenRateOrder(tokenIn, tokenOut) || [
    tokenIn.name,
    tokenOut.name,
  ]

  return (
    <Stack direction="row" justifyContent="space-between" className="w-full pt-6" flexShrink={1}>
      <Stack className="flex-1 text-right text-iw-dark-gray" alignItems="end">
        {isLoadingRate || exchangeRate === '-' || exchangeRate === '0' ? (
          <>
            <Skeleton variant="text" width={95} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width={137} sx={{ fontSize: '1rem' }} />
          </>
        ) : (
          <>
            {exchangeRate !== '-' && (
              <>
                <Typography>{trans.instantwire_transaction.draft_transaction.rate}</Typography>
                <Typography className="text-iw-neutral-black" variant="body1" fontWeight="bold">
                  {`${1} ${firstTokenName}`}
                  &nbsp;≈&nbsp;
                  <NumericFormat
                    value={exchangeRate}
                    decimalScale={FIAT_VND_OBJ.decimals}
                    thousandSeparator
                    valueIsNumericString
                    displayType="text"
                  />
                  &nbsp;{lastTokenName}
                </Typography>
              </>
            )}
          </>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" marginX={1}>
        <IconButton
          data-testid="exchange-rate-button"
          color="primary"
          className="rounded-full"
          onClick={handleRefreshRateClick}
          disabled={isLoadingRate}
        >
          <SVGIcon iconName={IconName.REFRESH} />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default Rate
