import Decimal from 'decimal.js'

const decimalDelimiter = '.'
const thousandDelimiter = ','

const stripInsignificantZeros = (numberValue: string) => {
  let numberString = `${numberValue}`
  if (numberString.indexOf(decimalDelimiter) > -1) {
    numberString = numberString.replace(new RegExp(`\\${decimalDelimiter}?0+$`), '')
  }
  return numberString
}

const formatThousandDelimiter = (numberString: string, delimiter: string) =>
  numberString.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)

export const format = (number: Decimal.Value, strip: boolean, numberDecimal?: number) => {
  const [ints, decimals] = (Decimal.isDecimal(number) ? number : processDecimalNumber(`${number}`))
    .toFixed(numberDecimal !== undefined ? numberDecimal : 2, Decimal.ROUND_DOWN)
    .split(decimalDelimiter)
  const formattedInts = formatThousandDelimiter(ints, thousandDelimiter)
  let result = formattedInts
  if (decimals) {
    result = `${formattedInts}${decimalDelimiter}${decimals}`
  }
  if (strip) {
    return stripInsignificantZeros(result)
  }
  return result
}

const processDecimalNumber = (numberString: string) => {
  // remove thousand delimiters
  let processedNumber = numberString.replace(thousandDelimiter, '')

  // if last character is a decimal delimiter, add a zero
  if (processedNumber[processedNumber.length - 1] === decimalDelimiter) {
    processedNumber = `${processedNumber}0`
  }

  // if first character is a decimal delimiter, add a zero
  if (processedNumber[0] === decimalDelimiter) {
    processedNumber = `0${processedNumber}`
  }

  return new Decimal(processedNumber || 0)
}
