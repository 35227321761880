import { Stack, Typography } from '@mui/material'
import type { SwapTokenInfo } from '@/hooks/use-tokens'

import useTrans from '@/hooks/useTrans'
import { format } from '@/root/src/utils/helpers.number'
import { NumericFormat } from 'react-number-format'
import React from 'react'
import { FIAT_USD_OBJ } from '@/contexts/swap'

interface FeeProps {
  tokenIn: SwapTokenInfo
  inputAmount: string
  discountAmount: number
  appliedDiscount: boolean
  fee: string
  originalFee?: string
  displayDiscountFee?: number
}

function Fee(props: FeeProps) {
  const { discountAmount, appliedDiscount, fee, displayDiscountFee, originalFee } = props
  const trans = useTrans()

  return (
    <Stack className="w-full mb-4 mt-4 text-iw-dark-gray" spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>{trans.transaction_input.fee}</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {!!displayDiscountFee && (
            <Typography
              fontWeight="bold"
              className="text-iw-rose-red text-sm sm:text-xs line-through"
            >
              <NumericFormat
                value={originalFee}
                decimalScale={FIAT_USD_OBJ.decimals}
                thousandSeparator
                displayType="text"
                suffix={' USD'}
              />
            </Typography>
          )}
          <Typography fontWeight="bold" className="text-iw-secondary text-md sm:text-sm">
            <NumericFormat
              value={fee}
              decimalScale={FIAT_USD_OBJ.decimals}
              thousandSeparator
              displayType="text"
              suffix={' USD'}
            />
          </Typography>
        </Stack>
      </Stack>
      {appliedDiscount && (
        <Stack direction="row" justifyContent="space-between">
          <Typography>{trans.discount.field}</Typography>
          <Typography className="text-iw-neutral-black font-semibold" data-testid="discount-amount">
            - {`${format(discountAmount, true, FIAT_USD_OBJ.decimals)} ${FIAT_USD_OBJ.name}`}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default Fee
