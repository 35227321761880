import { memo } from 'react'

import CustomNumericFormat from '@/components/base/CustomNumericFormat'
import { useEventCallback } from '@/utils/use-event-callback'
import { type InputBaseProps } from '@mui/material'
import InputBase from '@mui/material/InputBase'

type BaseAmountInputProps = InputBaseProps & {
  decimals?: number
}

const BaseAmountInput = ({ inputProps, decimals = 9, ...props }: BaseAmountInputProps) => {
  const onInputFocus: any = useEventCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (Number(event.target.value) === 0) {
      event.target.select()
    }
  })

  const onInputBlur: any = useEventCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.replaceAll(',', '')
    const formattedValue =
      Number(value) % 1 === 0 ? String(Number(value)) : Number(value).toFixed(2)

    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name || '',
          value: formattedValue,
        },
      } as any)
    }
  })

  return (
    <InputBase
      inputComponent={CustomNumericFormat as any}
      placeholder="0"
      autoComplete="off"
      inputProps={{
        'aria-label': '0',
        className:
          'p-0 font-semibold font-inter text-md leading-[16px] md:text-[20px] md:leading-[30px] text-right text-[#0F0E0C]',
        min: 0,
        ...(inputProps || {}),
        decimalScale: decimals,
        allowNegative: false,
        thousandSeparator: ',',
      }}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      {...props}
    />
  )
}

export default memo(BaseAmountInput)
